<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="top1">
				<div>
					<el-image class="top1_logo" :src="require('@/assets/img/logo.png')" :fit="'cover'"></el-image>
				</div>
				<div class="top1_login">
					<div class="to1_lo_textDiv">
						<div>
							<div class="i18nText" @click="i18nClick">{{language}}</div>
							<div class="i18nPhone">+86 0755-82056199</div>
						</div>
					</div>
					<div>
						<el-image class="top1_lg_icon" :src="require('@/assets/img/user.png')" :fit="'cover'"
							@click="login(3)"></el-image>
					</div>
					<div style="display: flex;" v-if="userInfo==null">
						<div class="top1_lg_text1" @click="login(1)">{{$t('top.text1')}}</div>
						<div class="top1_lg_text2" @click="login(2)">{{$t('top.text2')}}</div>
					</div>
					<div v-else>
						<el-dropdown size="medium" placement="bottom">
							<div>
								<div class="top1_lg_text3" v-if="role=='client'" @click="login(3)">{{$t('top.text4')}}，{{userInfo.contacttel}}</div>
								<div class="top1_lg_text3" v-else @click="login(4)">{{$t('top.text4')}}，{{userInfo.username}}</div>
							</div>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item @click.native="login(3)">订单管理</el-dropdown-item>
									<el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
									<!-- <el-dropdown-item @click="">清缓存</el-dropdown-item> -->
								</el-dropdown-menu>
							</template>
						</el-dropdown>
					</div>
				</div>
			</div>
			<div class="top2">
				<div class="top2_tabs" @mouseover="tabsMouseover(index)" @mouseleave="tabsMouseleave"
					v-for="(item,index) in $t('top.tabsList')" :key="index">
					<div class="top2_tabs_name" @click="tabsClick(index)">{{item.name}}</div>
					<!-- <transition enter-active-class="animated animate__fadeInDownBig" leave-active-class="animated animate__fadeOutUpBig" :duration="1000">
						<div class="top2_tabs_popover" v-if="item.list.length>0 && tabsIndex==index">
							<div class="top2_tabs_pop_line"></div>
							<div class="top2_tabs_pop_name">
								<div class="top2_tabs_pop_na_text" v-for="(item2,index2) in item.list" :key="index2">{{item2.name}}</div>
							</div>
						</div>
					</transition> -->
					<div class="top2_tabs_popover" v-if="item.list.length>0 && tabsIndex==index">
						<div class="top2_tabs_pop_line"></div>
						<div class="top2_tabs_pop_name">
							<div class="top2_tabs_pop_na_text" v-for="(item2,index2) in item.list" :key="index2"
								@click="tabsListClick(item2)">{{item2.name}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="top" v-if="windowWidth<=1199">
			<div class="top_div">
				<div>
					<img class="top_div_logo" src="@/assets/img/logo2.png" alt="" />
					
				</div>
				
				<div class="top_div_rightIcon" @click="drawerClick">
					<el-image class="rightIcon" :src="require('@/assets/img/more.png')" fit="cover"></el-image>
				</div>
			</div>
		</div>
		
		<el-drawer
		  title=""
		  size="80%"
		  :visible.sync="drawer"
		  :with-header="false">
		  <div class="drawer">
			  <div style="justify-content: center;display: flex;" v-if="windowWidth>768">
				  <div>
				  	<el-image style="width: 50px;height: 50px;" :src="require('@/assets/img/icon/user.png')" :fit="'cover'"
				  		@click="login(3)"></el-image>
				  </div>
				  <div style="display: flex;align-items: center;justify-content: center;" v-if="userInfo==null">
				  	<div style="color: #d1102d;margin-left: 10px;font-size: 21px;" @click="login(1)">{{$t('top.text1')}}</div>
				  	<div style="color: #d1102d;margin-left: 20px;margin-right: 10px;font-size: 21px;" @click="login(2)">{{$t('top.text2')}}</div>
				  </div>
				  
				  <div style="display: flex;align-items: center;" v-else>
				  	<el-dropdown size="medium" placement="bottom">
				  		<div>
				  			<div style="color: #d1102d;margin-left: 10px;font-size: 21px;" v-if="role=='client'" @click="login(3)">{{$t('top.text4')}}，{{userInfo.contacttel}}</div>
							<div style="color: #d1102d;margin-left: 10px;font-size: 21px;" v-else @click="login(4)">{{$t('top.text4')}}，{{userInfo.username}}</div>
				  		</div>
				  		<template #dropdown>
				  			<el-dropdown-menu>
				  				<el-dropdown-item @click="login(3)">订单管理</el-dropdown-item>
				  				<el-dropdown-item @click.native="logOut">退出登录</el-dropdown-item>
				  				<!-- <el-dropdown-item @click="">清缓存</el-dropdown-item> -->
				  			</el-dropdown-menu>
				  		</template>
				  	</el-dropdown>
				  </div>
			  </div>
			  
			  <div style="text-align: center;">
				  <!-- <div class="dr_tabs" v-if="userInfo!==null" @click="login(3)">
					  <div class="dr_tabs_name">{{$t('top.content1')}}<div class="dr_tabs_icon">></div></div>
				  </div> -->
				 <div class="dr_tabs" v-for="(item,index) in $t('top.tabsList')" :key="index">
					<div class="dr_tabs_name" @click="tabsClick(index)">{{item.name}}<div class="dr_tabs_icon">></div></div>
					<div v-for="(item2,index2) in item.list" :key="index2" @click="tabsListClick(item2)">
						<div class="dr_tabs_name">{{item2.name}}</div>
					</div>
				 </div>
				 <div class="dr_tabs_i18n" @click="i18nClick">{{language}}</div>
				 <div class="dr_phone">+86 0755-82056199</div>
			  </div>
		  </div>
		</el-drawer>
	</div>
</template>

<script>
	import WOW from "wowjs";
	import Cookies from "js-cookie";
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				language: 'EN', //中英文切换
				drawer: false,//是否打开弹框
				tabsList: [{
						id: 1,
						name: '首页',
						list: []
					},
					{
						id: 2,
						name: '智能报价',
						list: []
					},
					{
						id: 3,
						name: 'PCB制板',
						list: [{
								id: 1,
								name: "PCB制板"
							},
							{
								id: 2,
								name: "PCB批量"
							},
							{
								id: 3,
								name: "FPC批量"
							},
							{
								id: 4,
								name: "铝基板"
							}
						]
					},
					{
						id: 4,
						name: 'PCBA制造',
						list: [{
								id: 1,
								name: "SMT贴片"
							},
							{
								id: 2,
								name: "钢片"
							}
						]
					},
					{
						id: 5,
						name: '元器件供应',
						list: []
					},
					{
						id: 6,
						name: '解决方案',
						list: [{
								id: 1,
								name: "方案设计"
							},
							{
								id: 2,
								name: "Layout设计"
							}
						]
					},
					{
						id: 7,
						name: '工厂展示',
						list: [{
								id: 1,
								name: "工厂展示"
							},
							{
								id: 2,
								name: "层压结构"
							},
							{
								id: 3,
								name: "工艺能力"
							},
							{
								id: 4,
								name: "产品展示"
							},
							{
								id: 5,
								name: "活动专题"
							},
							{
								id: 6,
								name: "客户晒单"
							}
						]
					},
					{
						id: 8,
						name: '关于格物思',
						list: [{
								id: 1,
								name: "关于格物思"
							},
							{
								id: 2,
								name: "联系我们"
							}, {
								id: 3,
								name: "新闻资讯"
							}
						]
					}
				],
				tabsIndex: 0,
				userInfo: null,
				role:null,
			}
		},

		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.role = Cookies.get("role2")
			// console.log(this.userInfo)
			
			this.$i18n.locale = this.$store.state.selectForm.language;
			if (this.$i18n.locale == 'cn') {
				this.language = 'EN'
			} else {
				this.language = 'CN'
			}
		},

		mounted() {
			let wow = new WOW.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: true
			});
			wow.init();
			// console.log(this.windowWidth)
		},

		watch: {
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					this.language = 'EN';
					this.$store.dispatch("setLanguage", {
						language: 'cn',
					});
				} else {
					this.language = 'CN';
					this.$store.dispatch("setLanguage", {
						language: 'en',
					});
				}
				this.route = this.$route.path;
			},
			'$route'(to, from) {
				if (to.fullPath == "/login") {
					window.location.reload();
				}
			},
		},

		computed: {

		},

		methods: {
			//---------------------语言切换--------------------------
			i18nClick: function() {
				this.drawer = false;
				if (this.$i18n.locale == 'cn') {
					this.$i18n.locale = 'en';
					this.$store.dispatch("setLanguage", {
						language: 'en',
					});
					// console.log(this.$store.state.selectForm.language)
				} else {
					this.$i18n.locale = 'cn';
					this.$store.dispatch("setLanguage", {
						language: 'cn',
					});
					// console.log(this.$store.state.selectForm.language)
				}
			},
			
			//---------------------弹框打开-------------------------
			drawerClick:function(){
				this.drawer=true;
			},

			//--------------------移入事件----------------------------
			tabsMouseover: function(index) {
				// console.log('111')
				this.tabsIndex = index;
			},

			//------------------移出-----------------------
			tabsMouseleave: function() {
				this.tabsIndex = 0;
			},

			//-----------------登录/注册-----------------
			login: function(e) {
				console.log(e)
				if (e == 1) {
					this.$router.push({
						path: '/login',
					})
				} else if (e == 2) {
					this.$router.push({
						path: '/register',
					})
				} else if (e == 3) {
					this.$router.push({
						path: '/order/home',
					})
				} else {
					this.$router.push({
						path: '/erp/home',
					})
				}
			},

			tabsClick: function(index) {
				// console.log(index)
				// console.log("111",this.$t('top.tabsList'))
				// this.tabsIndex = index
				this.drawer = false;
				switch (index) {
					case 0:
						if (this.$route.path == '/') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/',
								query: {
									selector: null
								}
							})
						}
						break;
					case 1:
						if (this.$route.path == '/valuation') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/valuation',
							})
						}
						break;
					case 2:
						if (this.$route.path == '/boardMaking/pcbBoardMaking') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/boardMaking/pcbBoardMaking',
							})
						}
						break;
					case 3:
						if (this.$route.path == '/manufacture/pcbaMade') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/manufacture/pcbaMade',
							})
						}
						break;
					case 4:
						if (this.$route.path == '/bomSupply') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/bomSupply',
							})
						}
						break;
					case 5:
						if (this.$route.path == '/solution/schemeDesign') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/solution/schemeDesign',
							})
						}
						break;
					case 6:
						if (this.$route.path == '/factoryDisplay/displayFactory') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/displayFactory',
							})
						}
						break;
					case 7:
						if (this.$route.path == '/aboutUs/aboutUs') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/aboutUs/aboutUs',
							})
						}
						break;
					default:
						break;
				}
			},

			//-------------tabs列表跳转--------------------------
			tabsListClick: function(item) {
				// console.log("222",item)
				switch (item.id) {
					case 1:
						if (this.$route.path == '/boardMaking/pcbBoardMaking') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/boardMaking/pcbBoardMaking',
							})
						}
						break;
					case 2:
						// console.log("3333",item)
						if (this.$route.path == '/boardMaking/pcbBatch') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/boardMaking/pcbBatch',
							})
						}
						break;
					case 3:
						if (this.$route.path == '/boardMaking/fpcBatch') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/boardMaking/fpcBatch',
							})
						}
						break;
					case 4:
						if (this.$route.path == '/boardMaking/aluminumSubstrate') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/boardMaking/aluminumSubstrate',
							})
						}
						break;
					case 5:
						if (this.$route.path == '/manufacture/pcbaMade') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/manufacture/pcbaMade',
							})
						}
						break;
					case 6:
						if (this.$route.path == '/manufacture/steelMesh') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/manufacture/steelMesh',
							})
						}
						break;
					case 7:
						if (this.$route.path == '/solution/schemeDesign') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/solution/schemeDesign',
							})
						}
						break;
					case 8:
						if (this.$route.path == '/solution/layoutDesign') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/solution/layoutDesign',
							})
						}
						break;
					case 9:
						if (this.$route.path == '/factoryDisplay/displayFactory') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/displayFactory',
							})
						}
						break;
					case 10:
						if (this.$route.path == '/factoryDisplay/iaminatedStructure') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/iaminatedStructure',
							})
						}
						break;
					case 11:
						if (this.$route.path == '/factoryDisplay/processShow') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/processShow',
							})
						}
						break;
					case 12:
						if (this.$route.path == '/factoryDisplay/productList') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/productList',
							})
						}
						break;
					case 13:
						if (this.$route.path == '/factoryDisplay/activity') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/activity',
							})
						}
						break;
					case 14:
						if (this.$route.path == '/factoryDisplay/sunbathing') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/factoryDisplay/sunbathing',
							})
						}
						break;
					case 15:
						if (this.$route.path == '/aboutUs/aboutUs') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/aboutUs/aboutUs',
							})
						}
						break;
					case 16:
						if (this.$route.path == '/aboutUs/contactUs') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/aboutUs/contactUs',
							})
						}
						break;
					case 17:
						if (this.$route.path == '/aboutUs/news') {
							this.$router.go(0)
						} else {
							this.$router.push({
								path: '/aboutUs/news',
							})
						}
						break;
					case 18:
						if (this.$route.path == '/valuation') {
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 0,
							});
							this.drawer=false;
						} else {
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 0,
							});
							this.drawer=false;
						}
						break;
					case 19:
						if (this.$route.path == '/valuation') {
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 2,
							});
							this.drawer=false;
						} else {
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 2,
							});
							this.drawer=false;
						}
						break;
					case 20:
						// console.log("111")
						if (this.$route.path == '/valuation') {
							// console.log("222")
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 3,
							});
							this.drawer=false;
						} else {
							// console.log("333")
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 3,
							});
							this.drawer=false;
						}
						break;
					case 21:
						if (this.$route.path == '/valuation') {
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 4,
							});
							this.drawer=false;
						} else {
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 4,
							});
							this.drawer=false;
						}
						break;
					
					default:
						break;
				}
			},

			logOut: function() {
				if(this.role=='client'){
					sessionStorage.removeItem("setTabs")
					sessionStorage.removeItem("setTabs2")
					Cookies.remove('role2');
					sessionStorage.removeItem("meunList")
					Cookies.remove('userInfo2');
					this.$store.dispatch("setXlsxName", {
						xlsxName: null,
					});
					this.$store.dispatch("setBomSize", {
						bomSize: null,
					});
					this.$store.dispatch("setPlainOptions", {
						plainOptions: null,
					});
					this.$store.dispatch("setXlsxDataList", {
						xlsxDataList: [],
					});
					this.$store.dispatch("setBomDataList", {
						bomDataList: [],
					});
					this.$store.dispatch("setBomForm", {
						bomForm: null,
					});
					// console.log("222")
					this.$router.push({
						path: '/login',
					})
				}else{
					sessionStorage.removeItem("setTabs")
					sessionStorage.removeItem("setTabs2")
					Cookies.remove('role2');
					sessionStorage.removeItem("meunList")
					Cookies.remove('userInfo2');
					this.$store.dispatch("setXlsxName", {
						xlsxName: null,
					});
					this.$store.dispatch("setBomSize", {
						bomSize: null,
					});
					this.$store.dispatch("setPlainOptions", {
						plainOptions: null,
					});
					this.$store.dispatch("setXlsxDataList", {
						xlsxDataList: [],
					});
					this.$store.dispatch("setBomDataList", {
						bomDataList: [],
					});
					this.$store.dispatch("setBomForm", {
						bomForm: null,
					});
					// console.log("222")
					this.$router.push({
						path: '/userLogin',
					})
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.top1 {
			background: #d1102d;
			padding: 20px 200px;
			display: flex;
			position: relative;
			z-index: 5;

			.top1_logo {
				width: 200px;
				height: 50px;
			}

			.top1_login {
				position: absolute;
				display: flex;
				right: 200px;
				align-items: center;
				height: 50px;

				.to1_lo_textDiv {
					height: 100%;
					display: flex;
					align-items: center;
					padding-right: 20px;
					text-align: right;
					color: #fff;

					.to1_lo_td_text {
						border-radius: 20px;
						background: #fff;
						color: red;
						padding: 5px 30px;
						margin-right: 50px;
						cursor: pointer;
					}
				}

				.top1_lg_icon {
					width: 30px;
					height: 30px;
				}

				.top1_lg_text1 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
					border-right: 1px solid #fff;
				}

				.top1_lg_text2 {
					cursor: pointer;
					padding: 0 0 0 20px;
					color: #fff;
				}

				.top1_lg_text3 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
				}
			}
		}

		.top2 {
			padding: 0 200px;
			display: flex;
			position: relative;
			background: #404040;
		}

		.top2_tabs {
			position: relative;
			z-index: 4;
		}

		.top2_tabs_name {
			cursor: pointer;
			padding: 16px 40px;
			color: #fff;
			position: relative;
			// background: #404040;
			transition: all .5s;
			z-index: 4;
		}

		.top2_tabs:hover .top2_tabs_name {
			background: #d1102d;
		}

		.top2_tabs_popover {
			position: relative;
			background: #fff;
			height: 0;
			width: 100%;
			transform: all 2s;
			z-index: 6;
		}

		.top2_tabs_pop_line {
			background: #d1102d;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			z-index: 2;
		}

		.top2_tabs_pop_name {
			background: #000;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}

		.top2_tabs_pop_na_text {
			color: #fff;
			cursor: pointer;
			text-align: center;
			padding: 10px 0;
			border-bottom: 1px solid #1a1a1a;
		}

		.top2_tabs_pop_na_text:hover {
			background: #d1102d;
		}

		.i18nText {
			font-size: 14px;
			cursor: pointer;
			padding-bottom: 5px;
			transition: all .4s ease;
		}

		.i18nText:hover {
			color: #e5e5e5;
		}

		.i18nPhone {
			font-size: 14px;
		}
	}

	@media only screen and (max-width:1920px) {
		.top1 {
			background: #d1102d;
			padding: 20px 200px;
			display: flex;
			position: relative;
			z-index: 5;

			.top1_logo {
				width: 200px;
				height: 50px;
			}

			.top1_login {
				position: absolute;
				display: flex;
				right: 200px;
				align-items: center;
				height: 50px;

				.to1_lo_textDiv {
					height: 100%;
					display: flex;
					align-items: center;
					padding-right: 20px;
					text-align: right;
					color: #fff;

					.to1_lo_td_text {
						border-radius: 20px;
						background: #fff;
						color: red;
						padding: 5px 30px;
						margin-right: 50px;
						cursor: pointer;
					}
				}

				.top1_lg_icon {
					width: 30px;
					height: 30px;
				}

				.top1_lg_text1 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
					border-right: 1px solid #fff;
				}

				.top1_lg_text2 {
					cursor: pointer;
					padding: 0 0 0 20px;
					color: #fff;
				}

				.top1_lg_text3 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
				}
			}
		}

		.top2 {
			padding: 0 200px;
			display: flex;
			position: relative;
			background: #404040;
		}

		.top2_tabs {
			position: relative;
			z-index: 4;
		}

		.top2_tabs_name {
			cursor: pointer;
			padding: 16px 40px;
			color: #fff;
			position: relative;
			// background: #404040;
			transition: all .5s;
			z-index: 4;
		}

		.top2_tabs:hover .top2_tabs_name {
			background: #d1102d;
		}

		.top2_tabs_popover {
			position: relative;
			background: #fff;
			height: 0;
			width: 100%;
			transform: all 2s;
			z-index: 6;
		}

		.top2_tabs_pop_line {
			background: #d1102d;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			z-index: 2;
		}

		.top2_tabs_pop_name {
			background: #000;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}

		.top2_tabs_pop_na_text {
			color: #fff;
			cursor: pointer;
			text-align: center;
			padding: 10px 0;
			border-bottom: 1px solid #1a1a1a;
		}

		.top2_tabs_pop_na_text:hover {
			background: #d1102d;
		}

		.i18nText {
			font-size: 14px;
			cursor: pointer;
			padding-bottom: 5px;
			transition: all .4s ease;
		}

		.i18nText:hover {
			color: #e5e5e5;
		}

		.i18nPhone {
			font-size: 14px;
		}
	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.top1 {
			background: #d1102d;
			padding: 20px 140px;
			display: flex;
			position: relative;
			z-index: 5;

			.top1_logo {
				width: 200px;
				height: 50px;
			}

			.top1_login {
				position: absolute;
				display: flex;
				right: 140px;
				align-items: center;
				height: 50px;

				.to1_lo_textDiv {
					height: 100%;
					display: flex;
					align-items: center;
					padding-right: 20px;
					text-align: right;
					color: #fff;

					.to1_lo_td_text {
						border-radius: 20px;
						background: #fff;
						color: red;
						padding: 5px 30px;
						margin-right: 50px;
						cursor: pointer;
					}
				}

				.top1_lg_icon {
					width: 30px;
					height: 30px;
				}

				.top1_lg_text1 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
					border-right: 1px solid #fff;
				}

				.top1_lg_text2 {
					cursor: pointer;
					padding: 0 0 0 20px;
					color: #fff;
				}

				.top1_lg_text3 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
				}
			}
		}

		.top2 {
			padding: 0 140px;
			display: flex;
			position: relative;
			background: #404040;
		}

		.top2_tabs {
			position: relative;
			z-index: 4;
		}

		.top2_tabs_name {
			cursor: pointer;
			padding: 16px 40px;
			color: #fff;
			position: relative;
			// background: #404040;
			transition: all .5s;
			z-index: 4;
		}

		.top2_tabs:hover .top2_tabs_name {
			background: #d1102d;
		}

		.top2_tabs_popover {
			position: relative;
			background: #fff;
			height: 0;
			width: 100%;
			transform: all 2s;
			z-index: 6;
		}

		.top2_tabs_pop_line {
			background: #d1102d;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			z-index: 2;
		}

		.top2_tabs_pop_name {
			background: #000;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}

		.top2_tabs_pop_na_text {
			color: #fff;
			cursor: pointer;
			text-align: center;
			padding: 10px 0;
			border-bottom: 1px solid #1a1a1a;
		}

		.top2_tabs_pop_na_text:hover {
			background: #d1102d;
		}

		.i18nText {
			font-size: 14px;
			cursor: pointer;
			padding-bottom: 5px;
			transition: all .4s ease;
		}

		.i18nText:hover {
			color: #e5e5e5;
		}

		.i18nPhone {
			font-size: 14px;
		}
	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.top1 {
			background: #d1102d;
			padding: 20px 40px;
			display: flex;
			position: relative;
			z-index: 5;

			.top1_logo {
				width: 200px;
				height: 50px;
			}

			.top1_login {
				position: absolute;
				display: flex;
				right: 40px;
				align-items: center;
				height: 50px;

				.to1_lo_textDiv {
					height: 100%;
					display: flex;
					align-items: center;
					padding-right: 20px;
					text-align: right;
					color: #fff;

					.to1_lo_td_text {
						border-radius: 20px;
						background: #fff;
						color: red;
						padding: 5px 30px;
						margin-right: 50px;
						cursor: pointer;
					}
				}

				.top1_lg_icon {
					width: 30px;
					height: 30px;
				}

				.top1_lg_text1 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
					border-right: 1px solid #fff;
				}

				.top1_lg_text2 {
					cursor: pointer;
					padding: 0 0 0 20px;
					color: #fff;
				}

				.top1_lg_text3 {
					cursor: pointer;
					padding: 0 20px;
					color: #fff;
				}
			}
		}

		.top2 {
			padding: 0 40px;
			display: flex;
			position: relative;
			background: #404040;
		}

		.top2_tabs {
			position: relative;
			z-index: 4;
		}

		.top2_tabs_name {
			cursor: pointer;
			padding: 16px 40px;
			color: #fff;
			position: relative;
			// background: #404040;
			transition: all .5s;
			z-index: 4;
		}

		.top2_tabs:hover .top2_tabs_name {
			background: #d1102d;
		}

		.top2_tabs_popover {
			position: relative;
			background: #fff;
			height: 0;
			width: 100%;
			transform: all 2s;
			z-index: 6;
		}

		.top2_tabs_pop_line {
			background: #d1102d;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			z-index: 2;
		}

		.top2_tabs_pop_name {
			background: #000;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}

		.top2_tabs_pop_na_text {
			color: #fff;
			cursor: pointer;
			text-align: center;
			padding: 10px 0;
			border-bottom: 1px solid #1a1a1a;
		}

		.top2_tabs_pop_na_text:hover {
			background: #d1102d;
		}

		.i18nText {
			font-size: 14px;
			cursor: pointer;
			padding-bottom: 5px;
			transition: all .4s ease;
		}

		.i18nText:hover {
			color: #e5e5e5;
		}

		.i18nPhone {
			font-size: 14px;
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {
		.top{
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
			
			.top_div{
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 30px;
				
				.top_div_logo {
					margin: 16px 0;
					width: 150px;
					height: 40px;
				}
				
				.top_div_rightIcon{
					position: absolute;
					right: 0;
					display: flex;
					height: 100%;
					align-items: center;
					padding-right: 30px;
					
					.rightIcon{
						width: 40px;
						height: 24px;
					}
				}
			}
		}
		
		.drawer{
			padding: 60px 40px;
			position: relative;
			
			.dr_tabs{
				border-bottom: 1px solid #f5f5f5;
				
				.dr_tabs_name{
					font-size: 23px;
					padding: 40px;
					color: #333;
					position: relative;
					
					.dr_tabs_icon{
						position: absolute;
						top: 0;
						right: 0;
						font-size: 23px;
						height: 100%;
						display: flex;
						align-items: center;
					}
				}
			}
			
			.dr_phone{
				position: fixed;
				bottom: 0;
				padding: 20px;
				width: 80%;
				left: 20%;
				text-align: center;
				background: #fff;
			}
			
			.dr_tabs_i18n{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				font-size: 23px;
				padding: 40px;
				color: #d1102d;
			}
		}
	}

	@media only screen and (max-width:767px) {
		.top{
			z-index: 200;
			position: fixed;
			top: 0;
			width: 100%;
			box-shadow: 0px 0px 2px rgb(0 0 0 / 30%);
			
			.top_div{
				position: relative;
				background: #fff;
				display: flex;
				padding: 0 30px;
				
				.top_div_logo {
					margin: 16px 0;
					width: 150px;
					height: 40px;
				}
				
				.top_div_rightIcon{
					position: absolute;
					right: 0;
					display: flex;
					height: 100%;
					align-items: center;
					padding-right: 30px;
					
					.rightIcon{
						width: 40px;
						height: 24px;
					}
				}
			}
		}
		
		.drawer{
			padding: 60px 40px;
			position: relative;
			
			.dr_tabs{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				
				.dr_tabs_name{
					font-size: 23px;
					padding: 40px;
					color: #333;
					position: relative;
					
					.dr_tabs_icon{
						position: absolute;
						top: 0;
						right: 0;
						font-size: 23px;
						height: 100%;
						display: flex;
						align-items: center;
					}
				}
			}
			
			.dr_phone{
				position: fixed;
				bottom: 0;
				padding: 20px;
				width: 80%;
				left: 20%;
				text-align: center;
				background: #fff;
			}
			
			.dr_tabs_i18n{
				border-bottom: 1px solid #f5f5f5;
				position: relative;
				font-size: 23px;
				padding: 40px;
				color: #d1102d;
			}
		}
	}
</style>